import ProcessIcon from "@material-ui/icons/RotateRightRounded"
import { Maybe } from "functional/lib/Maybe"
import { matchEnum } from "functional/lib/match"
import { useParams } from "react-router"
import { FlexCol, FlexRow } from "../../../components/Flexbox"
import { useBidsList, useProcess } from "../../../hooks/ConectoHooks"
import { Id } from "../../../model/Model"
import { Process } from "../../../model/Process"
import { BuyRequestType } from "../../../model/buyRequest/BuyRequest"
import { BidIcon, ConectoGrid, DetailSection, FieldBoolean, FieldBuyRequest, FieldCompanies, FieldDate, FieldNumber, FieldText } from "../detail-components"
import { ButtonTabs, ConectoBadge, IdView, PageLoading, useScrollToTop } from "../components"
import { useRState } from "../../../functional/react/RState"
import { BidTable, bidColumns } from "../bid/table"
import { ProcessActions } from "./actions"

export const ProcessDetailPage = () => {

  const params = useParams()

  const type = params.type as BuyRequestType
  const buyRequestId = params.buyRequestId as string
  const id = params.id as string

  const buyRequest = {
    type: type,
    id: buyRequestId
  }

  const [data, loading, error] = useProcess(type, buyRequestId, id)

  useScrollToTop()

  return Maybe.match(data)({
    some: data => <ProcessDetail 
      buyRequest={buyRequest}  
      data={data}
    />,
    none: () => <PageLoading/>
  })
}

const ProcessDetail = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  const data = props.data

  return <FlexCol
    alignItems="stretch"
    justifyContent="flex-start"
    padding={16}
    style={{
      background: "#f5f5f5",
      minHeight: "100vh"
    }}
    gap={8}
  >
    
    <FlexCol
      alignItems="stretch"
      padding={16}
      style={{
        border: "1px solid #ddd",
        borderRadius: 4,
        background: "white",
        boxShadow: "0 0 8px #ddd"
      }}
    >
      <Header
        buyRequest={props.buyRequest}
        data={data}
      />

      <hr/>

      <DataSection
        buyRequest={props.buyRequest}
        data={data}
      />

      <hr/>

      <PriceSection
        data={data}
      />

      <hr/>

      <StateSection
        data={data}
      />

      <hr/>

      <TimeSection
        data={data}
      />

    </FlexCol>

    <Relations
      buyRequest={props.buyRequest}
      data={data}
    />

  </FlexCol>
}

export const EncryptedBadge = (
  props: {
    encrypted: Maybe<boolean>
  }
) => {
  return (props.encrypted ?? true) ?
    <ConectoBadge
      color="blue"
      children="🔒 Encriptado"
    /> :
    <ConectoBadge
      color="green"
      children="🔓 Desencriptado"
    />
}

const Header = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  return <FlexCol
    alignItems="stretch"
  >
    <FlexRow
      justifyContent="space-between"
    >

      <FlexRow
        gap={6}
        alignItems="center"
      >
        <ProcessIcon
          style={{
            fontSize: 48,
            color: "#888888"
          }}
        />
        <h1>
          Ronda {props.data.round} de {props.buyRequest.id}
        </h1>
      </FlexRow>

      <ProcessActions
        buyRequest={props.buyRequest}
        data={props.data}
      />

    </FlexRow>

    <EncryptedBadge
      encrypted={props.data.encrypted}
    />

    <IdView
      style={{
        width: "max-content",
      }}
      id={props.data.id}
    />

  </FlexCol>
}


const DataSection = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {
  return <DetailSection
    title="Datos"
  >
    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <FieldBuyRequest
          label="Pedido de Compra"
          buyRequest={props.buyRequest}
        />,
        <FieldNumber
          label="Ronda"
          value={props.data.round}
        />,
        <FieldText
          label="Tipo"
          value={
            matchEnum(props.data.type)({
              auction: "👨‍⚖️ Subasta",
              tender: "✉️ Licitación"
            })
          }
        />,
        
        <FieldBoolean
          label="Acepta Archivos"
          value={props.data.acceptsFileUpload}
        />,
        
        [{ xs: 2 }, <FieldText
          label="Comentario"
          value={props.data.comment}
        />],
        
        [{ xs: 12 }, <FieldCompanies
          label="Empresas Invitadas"
          companyList={props.data.guestIdList}
        />],
      ]}
    />

  </DetailSection>
}


const StateSection = (
  props: {
    data: Process
  }
) => 
  <DetailSection
    title="Estado"
  >
    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <FieldBoolean
          label="Encriptado"
          value={props.data.encrypted}
        />,
        [{ xs: 10 }, <FieldText
          label="Mensaje"
          value={props.data.message}
        />],
      ]}
    />

  </DetailSection>

const PriceSection = (
  props: {
    data: Process
  }
) =>
  <DetailSection
    title="Precios"
  >
    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <FieldBoolean
          label="Precio máximo definido"
          value={props.data.defineMaxPrice}
        />,
        <FieldNumber
          label="Precio Máximo"
          value={props.data.maximumPrice}
          decimals={2}
        />,
        <FieldText
          label="Mejor Precio Actual"
          value={props.data.currentBestPrice?.toFixed(2)}
        />,
        <FieldBoolean
          label="Costo máximo definido"
          value={props.data.defineMaxFixedCost}
        />,
        <FieldNumber
          label="Costo fijo Máximo"
          value={props.data.maxFixedCost}
          decimals={2}
        />,
        <FieldNumber
          label="Costo variable Máximo"
          value={props.data.maxVariableCost}
          decimals={2}
        />,
        
      ]}
    />
  </DetailSection>

const TimeSection = (
  props: {
    data: Process
  }
) => 
  <DetailSection
    title="Tiempos"
  >
    <ConectoGrid
      defaults={{
        xs: 2
      }}
      cells={[
        <FieldDate
          label="Fecha de Creación"
          value={props.data.creationDate}
        />,
        <FieldDate
          label="Fecha de Apertura"
          value={props.data.openDate}
        />,
        <FieldDate
          label="Fecha de Cierre"
          value={props.data.closeDate}
        />,
        <FieldDate
          label="Horario de cierre de consultas"
          value={props.data.closeConsultsDate}
        />,
        <FieldNumber
          label="Plazo Válido"
          value={props.data.validTermDays}
          units="días"
        />,
        <FieldNumber
          label="Plazo"
          value={props.data.definitionTermDays}
          units="días"
        />,
        <FieldBoolean
          label="Acepta Plazo de Pago Diferente"
          value={props.data.acceptsDifferentPayTerm}
        />,
        <FieldBoolean
          label='Acepta "Take or Pay" o "Deliver or Pay" Diferentes'
          value={props.data.acceptsDifferentCommitments}
        />,
        <FieldBoolean
          label="Plazo de pago mínimo definido"
          value={props.data.defineMinPayTerm}
        />,
        <FieldNumber
          label="Plazo de Pago Mínimo"
          value={props.data.minimumPayTermDays}
          units="días"
        />,
        
        
      ]}
    />

  </DetailSection>

type Tab =
  | "bids"

const Relations = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  const tab = useRState<Tab>(() => "bids")

  return <FlexCol
    alignItems="stretch"
    gap={8}
  >

    <ButtonTabs
      style={{
        alignSelf: "flex-start"
      }}
      state={tab}
      options={[
        { 
          value: "bids",
          icon: <BidIcon/>, 
          label: "Ofertas" 
        },
      ]}
    />

    <ProcessBidTable
      buyRequest={props.buyRequest}
      data={props.data}
    />

  </FlexCol>
}

const ProcessBidTable = (
  props: {
    buyRequest: {
      type: BuyRequestType
      id: Id
    }
    data: Process
  }
) => {

  const [list, loading, error] = useBidsList(props.buyRequest.type, props.buyRequest.id, props.data.id)

  return <BidTable
    buyRequest={props.buyRequest}
    processId={props.data.id ?? "-"}
    isLoading={loading}
    data={list}
    columns={[
      bidColumns.id,
      bidColumns.date,
      bidColumns.encrypted,
      bidColumns.company,
    ]}
  />
}