import React from "react"
import { Row, Table } from "react-bootstrap"
import { useBidsList, useCompany, useSellerBidsList } from "../hooks/ConectoHooks"
import { BooleanCheckEditor } from "../components/editors/boolean"
import { Bid, DecryptedBid, SpotOffer } from "../model/Bid"
import { Id } from "../model/Model"
import { GasBuyRequest } from "../model/buyRequest/GasBuyRequest"
import { none } from "functional/lib/core"
import { Process } from "../model/Process"
import { RState } from "../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import { useAppUser } from "../context/App"
import { formatUsd } from "../pages/admin/detail-components"
import { computeContractValueAndFee } from "../utils/fee"
import { FlexRow } from "../components/Flexbox"
import { DownloadBidCsvButton, bidCsvColumns } from "./csv"

export const SpotBidTable = (
  props: {

    buyRequest: GasBuyRequest
    process: Process
    assignable: boolean
    preAssignable: boolean

    selectedBidId?: RState<Maybe<Id>>
  }
) => {

  const user = useAppUser()

  const [bidList, loading, error] = 
    user?.roleList?.includes('buyer') ? 
      useBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        props.process.id ?? ""
      ) : 
      useSellerBidsList(
        props.buyRequest.type, 
        props.buyRequest.id ?? "", 
        props.process.id ?? "", 
        user?.training ?? false, 
        user?.companyIdList?.[0] ?? ""
      )

  const bidTable = bidList ? bidList.map(bid =>
    <SpotBidRow
      key={bid.id}
      buyRequest={props.buyRequest}
      process={props.process}
      bid={bid}
      assignable={props.assignable}
      assigned={bid.id === props.selectedBidId?.value}
      setAssigned={() => props.selectedBidId?.apply(() => bid.id)?.()}
    />) : null

  return <>
    <Table className='text text-secondary text-center'>
      <thead>
        <tr>
          <th align="center">Oferente</th>
          <th align="center">Precio</th>
          <th align="center">Valor de Contrato</th>
          {props.buyRequest.acceptsLessVolume ? <th align="center">Cantidad Ofertada</th> : null}
          {props.process.acceptsDifferentPayTerm ? <th align="center">Plazo de Pago</th> : null}
          {props.process.type == "tender" && props.process.acceptsDifferentCommitments ? <th align="center">Deliver or Pay</th> : null}
          {props.process.type == "tender" && props.process.acceptsDifferentCommitments ? <th align="center">Take or Pay</th> : null}
          <th align="center">Hora</th>
          {props.assignable ? <th align="center" className='text-success'>Seleccionar</th> : null}
          {props.preAssignable ? <th align="center" className='text-info'>Seleccionar</th> : null}

        </tr>
      </thead>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <tbody>
        <tr>
          <td>Cargando...</td>
        </tr>
      </tbody>}
      {bidList && <tbody>{bidTable}</tbody>}
    </Table>
    {user?.roleList?.includes('seller') && bidList && bidList.length == 0 && <Row><small>Tus Ofertas enviadas recién estarán visibles cuando la Ronda sea desencriptada</small></Row>}
    {user?.roleList?.includes('buyer') && bidList && bidList.length == 0 && <Row><small>Por el momento esta Ronda no tiene Ofertas</small></Row>}
    <FlexRow
      justifyContent="flex-end"
      padding={8}
    >
      <DownloadBidCsvButton
        buyRequest={props.buyRequest}
        process={props.process}
        bidList={bidList ?? []}
        columns={[
          bidCsvColumns.id,
          bidCsvColumns.date,
          bidCsvColumns.sellerCompany,
          bidCsvColumns.contractValue,
          bidCsvColumns.payTermDays,
          bidCsvColumns.deliverOrPay,
          bidCsvColumns.takeOrPay,
        ]}
      />
    </FlexRow>
  </>
}

const SpotBidRow = (
  props: {
    buyRequest: GasBuyRequest
    process: Process
    bid: Bid
    assignable: boolean
    assigned: boolean
    setAssigned: (value: boolean) => void
  }
) => {
  const bid = props.bid

  const encryptedOffer = bid.encrypted && props.process.type === "tender"
  const decryptedBid = bid as DecryptedBid
  const offer = encryptedOffer ? none : decryptedBid.offer as SpotOffer
  const encryptedText = "Encriptado"

  const [company, loading, error] = useCompany(bid.encrypted ? none : decryptedBid.sellerCompanyId)

  const { contractValue, fee } = computeContractValueAndFee(props.buyRequest, offer)

  return <tr className='text-center' key={bid.id}>
    <td>{bid.encrypted ? encryptedText : company ? company.displayName : ""}</td>
    <td>{encryptedOffer ? encryptedText : offer?.price?.toFixed(2)}</td>
    <td>{encryptedOffer ? encryptedText : formatUsd(contractValue)}</td>
    {props.buyRequest.acceptsLessVolume ?
      <td>{encryptedOffer ? encryptedText : offer?.amount}</td> :
      null
    }
    {props.process.acceptsDifferentPayTerm ? <td>{encryptedOffer ? encryptedText : offer?.payTermDays}</td> : null}
    {props.process.type == "tender" && props.process.acceptsDifferentCommitments ? 
      <td>{encryptedOffer ? encryptedText : offer?.commitments?.deliverOrPay?.toFixed(0) ?? "--"}%</td> : null
    }
    {props.process.type == "tender" && props.process.acceptsDifferentCommitments ? 
      <td>{encryptedOffer ? encryptedText : offer?.commitments?.takeOrPay?.toFixed(0) ?? "--"}%</td> : null
    }
    <td>{bid.date.toString().substring(16, 24)}</td>
    {props.assignable ? <td>
      <BooleanCheckEditor
        type="checkbox"
        value={props.assigned}
        onValueChanged={value => props.setAssigned(value)}
      /></td> : null}
  </tr>

}