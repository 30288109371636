import { StaticDecode, Type } from "@sinclair/typebox"
import { ListT, MaybeT } from "./utils"
import { TimestampT } from "./common"

export const ProcessTypeT = Type.Union([Type.Literal("auction"), Type.Literal("tender")])
export type ProcessType = StaticDecode<typeof ProcessTypeT>

export const ProcessT = Type.Object({

  id: MaybeT(Type.String()),

  type: ProcessTypeT,
  creationDate: TimestampT,
  openDate: TimestampT,
  closeDate: TimestampT,
  closeConsultsDate: MaybeT(TimestampT),
  guestIdList: ListT(Type.String()),
  round: Type.Number(),
  comment: MaybeT(Type.String()),
  validTermDays: Type.Optional(Type.Number()),
  definitionTermDays: MaybeT(Type.Number()),
  defineMaxPrice: MaybeT(Type.Boolean()),
  message: MaybeT(Type.String()),
  acceptsFileUpload: MaybeT(Type.Boolean()),

  maximumPrice: MaybeT(Type.Number()),

  defineMinPayTerm: MaybeT(Type.Boolean()),
  minimumPayTermDays: MaybeT(Type.Number()),
  maxFixedCost: MaybeT(Type.Number()),
  defineMaxFixedCost: MaybeT(Type.Boolean()),
  maxVariableCost: MaybeT(Type.Number()),

  currentBestPrice: MaybeT(Type.Number()),
  acceptsDifferentPayTerm: MaybeT(Type.Boolean()),

  acceptsDifferentCommitments: MaybeT(Type.Boolean()),

  encrypted: MaybeT(Type.Boolean()),

})
export type Process = StaticDecode<typeof ProcessT>
