import React, { useEffect, useState } from "react"
import { Button, Col, Collapse, Form, Row, Spinner } from "react-bootstrap"
import { ProcessEditor } from "./buy-request-flow/ProcessEditor"
import { useCurrentUser, useProcessList } from "./hooks/ConectoHooks"
import { validateProcess } from "./Validation"
import { Product } from "./model/Model"
import { BuyRequest, BuyRequestType } from "./model/buyRequest/BuyRequest"
import { useAppAsynchronism } from "./context/AppCall"
import { none, throws } from "functional/lib/core"
import { Process } from "./model/Process"
import { buyRequestSetDeserted } from "./client/buy-request"
import { processCreate } from "./client/process"
import { bidAuctionDecryptAll, bidTenderDecryptAll } from "./client/bid"
import { matchEnum, matchPartial } from "functional/lib/match"
import { useRState } from "./functional/react/RState"
import { FlexCol, FlexRow } from "./components/Flexbox"
import { Draft } from "./model/utils"
import { useAppUser } from "./context/App"
import { List } from "functional/lib/List"
import { useFormState } from "./functional/react/form/state"
import { processValidator } from "./buy-request-flow/BuyRequestCreateProcessStep"
import { FormErrorsViewer } from "./components/Form"
import { IO } from "functional/lib/IO"
import { FormReactContext } from "./functional/react/form/FormContext"
import { deviceSizes, useDeviceSize } from "./hooks/responsive"


export const ActionPanel = (
  props: {
    buyRequest: BuyRequest
    lastProcess: Process
    assignable: boolean
    setAssignable: (boolean: boolean) => void
  }
) => {

  return (props.lastProcess.encrypted ?? true) ?
    <DecryptForm
      buyRequest={props.buyRequest}
      lastProcess={props.lastProcess}
    /> :
    <DecryptedOptions
      buyRequest={props.buyRequest}
      assignable={props.assignable}
      setAssignable={props.setAssignable}
      lastProcess={props.lastProcess}
    />
}

const processDraftFromLast = (lastProcess: Process): Draft<Process> => ({
  type: lastProcess.type,
  id: none,
  creationDate: none,
  openDate: none,
  closeDate: none,
  encrypted: none,
  round: lastProcess.round + 1,
  closeConsultsDate: none,
  currentBestPrice: none,
  definitionTermDays: lastProcess.definitionTermDays,
  comment: "",
  
  guestIdList: lastProcess.guestIdList,
  defineMaxPrice: lastProcess.defineMaxPrice,
  maximumPrice: lastProcess.maximumPrice,

  acceptsDifferentPayTerm: lastProcess.acceptsDifferentPayTerm,
  acceptsDifferentCommitments: lastProcess.acceptsDifferentCommitments,
  defineMinPayTerm: lastProcess.defineMinPayTerm,
  minimumPayTermDays: lastProcess.minimumPayTermDays,

  defineMaxFixedCost: lastProcess.defineMaxFixedCost,
  maxFixedCost: lastProcess.maxFixedCost,

  message: lastProcess.message,
  acceptsFileUpload: lastProcess.acceptsFileUpload,

  validTermDays: lastProcess.validTermDays,
  maxVariableCost: lastProcess.maxVariableCost,
})


const DecryptedOptions = (
  props: {
    buyRequest: BuyRequest
    assignable: boolean
    setAssignable: (value: boolean) => void
    lastProcess: Process
  }
) => {

  const openNewRound = useRState(() => false);

  const [openDeserted, setOpen] = useState(false);

  const callDeserted = useAppAsynchronism(buyRequestSetDeserted)

  const [comment, setComment] = useState("")

  const deviceSize = useDeviceSize()
  const small = deviceSize < deviceSizes.lg

  return <>
    <Row 
      className='justify-content-center'
      style={{
        alignSelf: "stretch",
        gap: small ? 8 : 0,
        padding: 0,
        margin: 0,
      }}
    >

      <Col xs={12} md={3}>
        <Button variant="outline-success"
          onClick={() => {
            props.setAssignable(!props.assignable);
            setOpen(false);
            openNewRound.apply(() => false)?.();
          }}
        >
          Adjudicar
        </Button>
      </Col>

      <Col xs={12} md={3}>
        <Button 
          variant="outline-primary"
          onClick={() => {
            openNewRound.apply(it => !it)?.();
            setOpen(false);
            props.setAssignable(false)
          }}
          aria-controls="example-collapse-processForm"
          aria-expanded={openNewRound.value}
        >
          Nueva Ronda
        </Button>
      </Col>

      <Col xs={12} md={3}>
        <Button variant="outline-danger"
          onClick={() => {
            props.setAssignable(false)
            setOpen(!openDeserted);
            openNewRound.apply(() => false)?.();
          }}
          aria-controls="example-collapse-processForm"
          aria-expanded={openDeserted}
        >
          Declarar Desierto
        </Button>
      </Col>
    </Row>

    <Collapse in={openNewRound.value}>
      <div>
        <NewRoundForm
          buyRequest={props.buyRequest}
          lastProcess={props.lastProcess}
        />
      </div>
    </Collapse>

    <Collapse in={openDeserted}>
      <div>
        <hr />
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label><h6 className='text-secondary'>Declarar el Pedido de Compra Desierto</h6>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={event => setComment(((event.target as any).value))}
          />
        </Form.Group>
        {
          callDeserted.state.type === "running" ? (
            <Spinner animation="border" role="status"/>
          ) : (
            <Button
              onClick={callDeserted.run({
                product: props.buyRequest.type,
                buyRequestId: props.buyRequest.id ?? "",
                comment: comment
              })}
              variant="outline-danger"
            >
              Desierto
            </Button>
          )
        }
      </div>
    </Collapse><br />
  </>
}


const NewRoundForm = (
  props: {
    buyRequest: BuyRequest
    lastProcess: Process
  }
) => {

  const user = useAppUser()

	const form = useFormState({
		initialDraft: () => processDraftFromLast(props.lastProcess),
		validator: processValidator
	})

  const callNewRound = useAppAsynchronism(processCreate)

  return <FormReactContext.Provider
    value={form}
  > 
    <FlexCol
      alignItems="stretch"
      gap={8}
    >
      <hr />
      <ProcessEditor
        request={props.buyRequest}
        process={form.state}
        buyRequestId={props.buyRequest.id}
      />
      {user &&
        <Button
          onClick={
            form.validated === none ?
              form.showErrorsState.apply(() => true) :
              callNewRound.run({
                buyRequest: {
                  type: props.buyRequest.type,
                  id: props.buyRequest.id ?? ""
                },
                process: form.validated
              })
            }
          >
          Publicar Nueva Ronda
        </Button>
      }

      <FormErrorsViewer
        form={form}
      />
    </FlexCol>
  </FormReactContext.Provider>
}


const DecryptForm = (
  props: {
    lastProcess: Process
    buyRequest: BuyRequest
  }
) => {

  const password = useRState(() => "")

  const progress = useRState(() => ({
    decrypted: 0,
    total: 0
  }))

  const decrypt = useAppAsynchronism(
    matchEnum(props.lastProcess.type)({
      tender: bidTenderDecryptAll,
      auction: bidAuctionDecryptAll
    })
  )
  const onSubmit = decrypt.run({
    product: props.buyRequest.type,
    buyRequestId: props.buyRequest.id ?? "",
    processId: props.lastProcess.id ?? "",
    password: password.value,
    progress: (decrypted, total) => 
      progress.apply(() => 
        ({ 
          decrypted: decrypted,
          total: total
        })
      )
  })

  return <FlexCol
    alignItems="stretch"
  >
    {
      matchPartial(decrypt.state)<React.ReactNode>({
        running: () => `Desencriptando Ofertas: ${progress.value.decrypted + 1} de ${progress.value.total} ...`,
        error: () => <p style={{ color: "red" }}>Error al desencriptar</p>,
      })
    }
    <hr />
    <FlexRow
      justifyContent="center"
      gap={8}
    >

      <Form.Control
        style={{
          maxWidth: 400
        }}
        type="password"
        value={password.value}
        onChange={event => {
          password.apply(() => event.target.value)()
        }}
        onKeyDown={event => {
          if (event.key === "Enter") {
            onSubmit()
          }
        }}
        placeholder="Ingrese la contraseña"
      />


      <Button
        variant="primary"
        onClick={onSubmit}
      >
        Desencriptar
      </Button>

    </FlexRow>

  </FlexCol>
}
