import React, { Component } from 'react';
import Herosection from './widgets/herosection';
import About from './widgets/about';
import {Footer} from "./widgets/footer";
import Feature from "./widgets/feature";
import GasCarrousel from './widgets/GasCarrousel';
import EECarrousel from './widgets/EECarrousel';

import './App.css';
import './Vendor.jsx';
import {Testimonial} from './widgets/testimonial'
export class Index extends Component {
  constructor(props)
  {
      super(props)
  }
  componentDidMount() {
      window.scrollTo(0, 0)
  }
    render() {
        return (
            <div
              style={{
                overflowX: "hidden",
              }}
            >
            {/*hero section start*/}
            <Herosection />

            {/*hero section end*/}
            <About />
            {/*body content start*/}


            <div className="page-content">
              {/*client start*/}
              <section className="p-0">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h1 className="mt-4 text-center text-secondary">Proveedores de Gas Natural</h1><br/>
                    </div>
                  </div>
                  <br/>
                <GasCarrousel/>
            
                  {/* <div>
                  <div className="row justify-content-center">
                  <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/pae.png`)} alt="" />
                      </div>
                    </div>
                  <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/tecpetrol.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/pampa.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/total.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/pluspe.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/vista.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/enap.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/pcr.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/exxon.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/roch.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/aconcagua.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/cgc.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/metroenergia.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/camuzzi.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/naturgy.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/saesa.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/alpes.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/albanesi.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/corocotta.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/apahie.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/gasMeridional.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/cinergia.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/trafigura.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/energyTraders.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/enel.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/victorio.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/e-nova.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/amg.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/lahorqueta.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/gasPatagonia.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/tranangas.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/delta.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/genneia.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/gasmarket.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/ECS.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/ambar.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/fenix.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/gea.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/gasener.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/we.png`)} alt="" />
                      </div>
                    </div>
                  </div>
                  </div> */}
                </div>
              </section>

              <br/>

              <section className="p-0">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h1 className="mt-4 text-center text-secondary">Proveedores de Energía Eléctrica</h1><br/>
                    </div>
                  </div>
                  <br/>
                  <EECarrousel/>
                  {/* <div>
                  <div className="row justify-content-center">
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/ypfLuz.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/pampa.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/genneia.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/cp.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/pcr.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/aes.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/msu.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/capex.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/360.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/diaser.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/harz.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/aconcagua.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/eoliasur.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/efesa.png`)} alt="" />
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="clients-logo">
                        <img className="img-fluid" src={require(`./assets/images/client/enel-x.png`)} alt="" />
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div className="shape-3" style={{height: '200px', overflow: 'hidden'}}>
                  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
                    <path d="M-5.00,49.98 C150.00,150.00 349.20,-50.00 505.00,49.98"
                          style={{
                            stroke: '007bff', 
                            //'stroke-width': "7", 
                            fill: 'none'
                            }}
                      />
                  </svg>
                </div> */}
              </section>
             
              {/*client end*/}

              {/*<section className="custom-py-2 position-relative bg-primary">*/}
              {/*  <div className="container">*/}
              {/*    <div className='row justify-content-center'>*/}
              {/*      <div className='col-9 text-center'>*/}
              {/*        <h2 className="mt-4  text-white"><b>Testimonios de nuestros clientes</b></h2>*/}
              {/*        <p className="lead mb-0 text-white">Todo tipo de industrias utilizan nuestra plataforma,<br/> les aseguramos un proceso transparente y competitivo.</p>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!* / .row *!/*/}
              {/*    <Testimonial2 />*/}
              {/*  </div>*/}
              {/*  <div className="shape-1" style={{ height: '200px', overflow: 'hidden' }}>*/}
              {/*    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>*/}
              {/*      <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />*/}
              {/*    </svg>*/}
              {/*  </div>*/}
              {/*  <div className="shape-1 bottom" style={{ height: '200px', overflow: 'hidden' }}>*/}
              {/*    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>*/}
              {/*      <path d="M0.00,49.98 C150.00,150.00 349.20,-50.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: 'none', fill: '#fff' }} />*/}
              {/*    </svg>*/}
              {/*  </div>*/}
              {/*</section>*/}
              {/* <section className='custom-py-1'>
                <div className='row justify-content-center'>
                  <div className='col-9 text-center'>
                    <h2 className="mt-4">Testimonios de nuestros clientes</h2>
                    <p className="lead mb-0">Todo tipo de industrias utilizan nuestra plataforma, les aseguramos un proceso transparente y competitivo.</p>
                  </div>
                </div>
                <Testimonial />
              </section> */}
              {/*about start*/}
              {/*about end*/}
              <section>
                {/*feature start*/}
                <Feature />
                {/*feature end*/}
              </section>
              {/*blog start*/}
              {/*<section>*/}
              {/*  <div className="container">*/}
              {/*    <div className="row justify-content-center text-center">*/}
              {/*      <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">*/}
              {/*          <h2 className="mt-4">Últimas notas de nuestro blog</h2>*/}
              {/*          <p className="lead mb-0">Mantenete al tanto del estado de la industria</p>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    /!* / .row *!/*/}
              {/*  </div>*/}
              {/*  <div className="container">*/}
              {/*  <Blog2 />*/}
              {/*   </div>*/}
              {/*</section>*/}
              {/*blog end*/}
            </div>
            {/*body content end*/}
            <Footer/>
          </div>
        );
    }
}

export default Index;