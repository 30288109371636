import { List } from "functional/lib/List"
import { Process } from "../model/Process"
import { BuyRequest } from "../model/buyRequest/BuyRequest"
import { AnnualOffer, Bid, MonthlyOffer, SpotOffer } from "../model/Bid"
import { Maybe, showIf } from "functional/lib/Maybe"
import { Unit, none } from "functional/lib/core"
import { dataToCsv, downloadTextFile } from "../utils/csv"
import { computeAnnualWeightedAveragePrice, computeMonthlyWeightedAveragePrice, dateToBidFormat, validaDateForBid } from "../Utilities"
import { computeContractValue } from "../utils/fee"
import { IO } from "functional/lib/IO"
import { Company } from "../model/Company"
import { Async } from "functional/lib/Async"
import { dbCompanyCollection } from "../hooks/ConectoHooks"
import { AppCall, useAppAsynchronism } from "../context/AppCall"
import { companyGet } from "../client/company"
import { Button, Spinner } from "react-bootstrap"
import { FlexRow } from "../components/Flexbox"
import { DownloadIcon } from "../pages/admin/detail-components"
import { matchPartial } from "functional/lib/match"


export type BidCsvColumn = {
  header: string
  cell: (
    args: {
      buyRequest: BuyRequest
      process: Process
      company: Maybe<Company>
      bid: Bid
    }
  ) => string
}

const buildColumns = <K extends string>(records: Record<K, BidCsvColumn>) => records

const annualOffer = (bid: Bid): Maybe<AnnualOffer> =>
  bid.offer?.type === "annual" ? bid.offer : none

const monthlyOffer = (bid: Bid): Maybe<MonthlyOffer> =>
  bid.offer?.type === "monthly" ? bid.offer : none

const spotOffer = (bid: Bid): Maybe<SpotOffer> =>
  bid.offer?.type === "spot" ? bid.offer : none

export const bidCsvColumns = buildColumns({
  id: {
    header: "Id",
    cell: it => it.bid.id
  },
  date: {
    header: "Fecha",
    cell: it => dateToBidFormat(it.bid.date)
  },
  sellerCompany: {
    header: "Oferente",
    cell: it => it.company?.displayName ?? "-"
  },
  winterPrice: {
    header: "Precio Invierno",
    cell: it =>
      annualOffer(it.bid)?.winterPrice?.toFixed(2) ?? "-"
  },
  summerPrice: {
    header: "Precio Verano",
    cell: it =>
      annualOffer(it.bid)?.summerPrice?.toFixed(2) ?? "-"
  },
  annualAverage: {
    header: "Precio Promedio",
    cell: it =>
      computeAnnualWeightedAveragePrice(it.buyRequest, annualOffer(it.bid)).toFixed(2) ?? "-"
  },
  monthlyAverage: {
    header: "Precio Promedio",
    cell: it =>
      computeMonthlyWeightedAveragePrice(it.buyRequest, monthlyOffer(it.bid)).toFixed(2)
  },
  spotPrice: {
    header: "Precio",
    cell: it =>  spotOffer(it.bid)?.price?.toFixed(2) ?? "-"
  },
  contractValue: {
    header: "Valor de Contrato (USD)",
    cell: it =>
      computeContractValue(it.buyRequest, it.bid.offer)?.toFixed(2) ?? "-"
  },
  payTermDays: {
    header: "Plazo de Pago (días)",
    cell: it => {
      const offer = it.bid.offer
      return matchPartial(offer ?? { type: "_" as const })({
        annual: it => it.payTermDays,
        monthly: it => it.payTermDays,
        spot: it => it.payTermDays
      })?.toString() ?? "-"
    }
  },
  deliverOrPay: {
    header: "Deliver or Pay (%)",
    cell: it => {
      const offer = it.bid.offer
      return matchPartial(offer ?? { type: "_" as const })({
        annual: it => it.commitments?.deliverOrPay,
        monthly: it => it.commitments?.deliverOrPay,
        spot: it => it.commitments?.deliverOrPay,
      })?.toFixed(0) ?? "-"
    }
  },
  takeOrPay: {
    header: "Take or Pay (%)",
    cell: it => {
      const offer = it.bid.offer
      return matchPartial(offer ?? { type: "_" as const })({
        annual: it => it.commitments?.takeOrPay,
        monthly: it => it.commitments?.takeOrPay,
        spot: it => it.commitments?.takeOrPay,
      })?.toFixed(0) ?? "-"
    }
  },
  validUntil: {
    header: "Válida hasta",
    cell: it =>
      validaDateForBid(it.process.closeDate, annualOffer(it.bid)?.validTermDays)
  },
  pdfs: {
    header: "PDF's",
    cell: it =>
      annualOffer(it.bid)?.decryptedPdfFiles?.map(file => file.link).join(",") ?? "-"
  }
})

export const downloadBidCsv =
  (
    args: {
      buyRequest: BuyRequest
      process: Process
      bidList: List<Bid>
      columns: List<BidCsvColumn>
    }
  ): AppCall<Unit> => 
  AppCall.do(async _ => {

    const buyRequest = args.buyRequest
    const process = args.process

    const rowData = await _(AppCall.forEach(args.bidList)(bid =>
      AppCall.do(async _ => {

        const company = bid.encrypted ? none : await _(companyGet(bid.sellerCompanyId))

        return {
          buyRequest: buyRequest,
          process: process,
          company: company,
          bid: bid
        }
      })  
    ))

    const data = dataToCsv(rowData)({
      columns: args.columns
    })

    downloadTextFile({
      type: "text/csv",
      name: `${buyRequest.id}-ofertas.csv`,
      content: data,
    })()

  })



export const DownloadBidCsvButton = (
  props: {
    buyRequest: BuyRequest
    process: Process
    bidList: List<Bid>
    columns: List<BidCsvColumn>
  }
) => {

  const enable = !(props.process.encrypted ?? true) && props.bidList.length > 0

  const call = useAppAsynchronism(downloadBidCsv)

  return <Button
    size="sm"
    variant="light"
    onClick={call.run(props)}
  >
    {
      showIf(enable)(
        <FlexRow
          gap={8}
          style={{ color: "#444444" }}
        >
          {call.state.type === "running" ? <Spinner/> : <DownloadIcon/>}
          Descargar CSV
        </FlexRow>
      )
    }
  </Button>
}
