import React from "react"
import { Col, Form, Row, Tooltip } from "react-bootstrap"
import { Commitments, Offer } from "../model/Bid"
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay"
import { GasBuyRequest } from "../model/buyRequest/GasBuyRequest"
import { none } from "functional/lib/core"
import { Process } from "../model/Process"
import { CellCol, FlexCol, FlexRow } from "../components/Flexbox"
import { Draft } from "../model/utils"
import { RState } from "../functional/react/RState"
import { Maybe } from "functional/lib/Maybe"
import { NumberEditor } from "../components/editors/number"


export const MonthInput = (
  props: {
    name: string
    id: string
  }
) =>
  <FlexRow>

    <Form.Label
      style={{ width: 0, flexGrow: 1 }}
    >{props.name}</Form.Label>

    <Form.Control
      style={{ width: 0, flexGrow: 1 }}
      id={props.id}
      type="number"
      placeholder="dam3/mes" 
    />

  </FlexRow>


export const DifferentPayTerm = (
  props: {
    fieldId?: string
    buyRequest: GasBuyRequest
    payTermDays?: RState<Maybe<number>>
    process: Process
  }
) => {

  return <FlexCol
    alignItems="stretch"
  >
    <h6><b>Plazo de Pago</b></h6>
    <FlexRow
      gap={16}
    >
      <CellCol
        alignItems="flex-start"
      >
        <p>Ofertado (días corridos)</p>
        <NumberEditor
          fieldId={props.fieldId}
          placeholder="días"
          state={props.payTermDays}
        />
      </CellCol>

      <CellCol
        alignItems="flex-start"
      >
        <p>Sugerido</p>
        <h6 style={{ color: "gray" }}>{props.buyRequest.referencePayTermDays} días</h6>
      </CellCol>

      <CellCol
        alignItems="flex-start"
      >

        {
          Maybe.map(props.process.minimumPayTermDays)(min =>
            <>
              <p>Mínimo</p>
              <h6 style={{ color: "gray" }}>{min} días</h6>
            </>  
          )
        }

      </CellCol>

    </FlexRow>
  </FlexCol>

}

export const CommitmentsEditor = (
  props: {
    state: RState<Commitments>
    fieldIds: {
      deliverOrPay: string
      takeOrPay: string
    }
  }
) => {

  const fields = RState.destructureAll(props.state)

  return <Row
    gap={16}
  >

    <CellCol
      alignItems="flex-start"
    >
      <p>Deliver or Pay (%)</p>
      <NumberEditor
        fieldId={props.fieldIds.deliverOrPay}
        placeholder="Deliver or Pay"
        state={fields.deliverOrPay}
      />
    </CellCol>

    <CellCol
      alignItems="flex-start"
    >
      <p>Take or Pay (%)</p>
      <NumberEditor
        fieldId={props.fieldIds.takeOrPay}
        placeholder="Take or Pay"
        state={fields.takeOrPay}
      />
    </CellCol>

  </Row>
}

export const RenderTooltipPPP = 
  (props: OverlayInjectedProps) => <Tooltip {...props} show={true}>Precio Promedio Ponderado sobre la curva Demandada</Tooltip>

export const RenderTooltipPriceOutOfRange = 
  (props: OverlayInjectedProps) => <Tooltip {...props} show={true}>El precio promedio a ofertar tiene que ser menor al último precio ofertado con un límite de -10%.</Tooltip>

export const RenderTooltipPPPLessVolume = 
  (props: OverlayInjectedProps) => <Tooltip {...props} show={true}>Precio Promedio Ponderado sobre la curva Ofertada</Tooltip>

export const RenderTooltipPriceOutOfRangeSpotAuction = 
  (props: OverlayInjectedProps) => <Tooltip {...props} show={true}>El precio promedio a ofertar tiene que ser menor al último precio ofertado con un límite de -10%.</Tooltip>

export const RenderTooltipPriceOutOfRangeSpotTender = 
  (props: OverlayInjectedProps) => <Tooltip {...props} show={true}>Tu precio a ofertar debe ser menor al precio máximo establecido para este pedido.</Tooltip>
